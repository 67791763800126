import { Component, OnInit } from "@angular/core";
import { AuthService } from "../common/share/service.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-startsubscription",
  templateUrl: "./startsubscription.component.html",
  styleUrls: ["./startsubscription.component.css"]
})
export class StartsubscriptionComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) { }
  token;
  ngOnInit() {
    this.checkingpage();
    this.ckeckpage();
  }
  ckeckpage() {
    this.token = localStorage.getItem("token");
    if (this.token == null) {
      this.router.navigate(["/signin"]);
    }
  }
  submit() {
    console.log("start subscription");
    this.auth.getStartSubscription().subscribe(result => {
      console.log("subscribtion start", result);
      this.router.navigate(["/dashboard"]);
    });
  }

  home() {
    window.location.href = "https://ndelish.com/";
  }

  logout() {
    this.auth.deleteToken();
    window.location.href = "https://ndelish.com/";
  }

  checkingpage() {
    
      this.router.navigate(["/dashboard"]);
         
     
  }
}
