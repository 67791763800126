import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../common/share/service.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SimpleDialogComponent } from "../common/dialog/simple-dialog.component";

@Component({
  selector: "app-forgotpass",
  templateUrl: "./forgotpass.component.html",
  styleUrls: ["./forgotpass.component.css"]
})
export class ForgotpassComponent implements OnInit {
  formForgot: FormGroup;
  public loading = false;
  // notemail: string;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formForgot = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
          )
        ]
      ]
    });
  }

  submit() {
    this.loading = true;
    let val = this.formForgot.value;
    console.log(val);
    this.auth.ForgotPassword(val).subscribe(
      result => {
        console.log("success please check your mail", result);
        // this.router.navigate(["/signin"]);
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
          width: "350px",
          data: { message: "Please check your e-mail for password reset link" }
        });
        const _this = this;
        dialogRef.afterClosed().subscribe(function () {
          _this.router.navigate(["/signin"]);
        });
        this.loading = false;
      },

      error => {
        this.loading = false;
        console.log("your mail is not registered", error);
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
          width: "350px",
          data: { message: this.auth.formErrorMessage(error.error) }
          // data: { message: this.auth.formErrorMessage(error.message) }
        });
      }
    );
  }
}
