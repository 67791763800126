import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../common/share/service.service";
import { MatSnackBar, ErrorStateMatcher } from "@angular/material";
// import { Component, Inject } from '@angular/core';
import { SimpleDialogComponent } from "../common/dialog/simple-dialog.component";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-resetpass",
  templateUrl: "./resetpass.component.html",
  styleUrls: ["./resetpass.component.css"]
})
export class ResetpassComponent implements OnInit {
  formReset: FormGroup;
  public loading = false;
  hide = true;
  token;
  matcher = new MyErrorStateMatcher();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
    this.getToken();
    this.verifyToken();
  }

  getToken() {
    this.route.queryParams.subscribe(params => {
      // console.log("token", params);
      this.token = params;
    });
  }
  verifyToken() {
    let val = this.token;
    this.auth.VerifyPassToken(val).subscribe(
      result => {
        // console.log(result);
        // console.log("your token is verified", result);
      },
      error => {
        console.log(error);
        let msg = "Your token seems invalid. Please retry with a valid token.";
        // console.log(error);
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
          width: "350px",
          data: { message: msg }
        });
        const _this = this;
        dialogRef.afterClosed().subscribe(function () {
          _this.router.navigate(["/forgotpassword"]);
        });
        // this.router.navigate(["/forgotpassword"]);
      }
    );
  }

  initForm() {
    this.formReset = this.fb.group(
      {
        token: [""],
        password: ["", Validators.required],
        conPassword: ["", Validators.required]
      },
      { validator: this.checkPasswords }
    );
  }

  submit(): void {
    this.loading = true;

    let val = this.formReset.value;
    delete this.formReset.value.conpassword;
    let d = this.token;
    this.formReset.value.token = d.token;
    console.log("token sub", d);
    console.log(val);
    this.auth.SetPassword(val).subscribe(
      result => {
        console.log("your password is update", result);
        this.loading = false;
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
          width: "350px",
          data: { message: "Your password is updated successfully." }
        });
        const _this = this;
        dialogRef.afterClosed().subscribe(function () {
          _this.router.navigate(["/signin"]);
        });
      },
      error => {
        this.loading = false;
        // console.log("your password is not update", error.error);
        let err = error.error;
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
          width: "350px",
          data: { message: this.auth.formErrorMessage(err) }
        });
      }
    );
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.get("password").value;
    let confirmPass = group.get("conPassword").value;
    return pass === confirmPass ? null : { notSame: true };
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
