import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Week } from "./getdata/weeklydata";
import { strictEqual } from "assert";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.url;

  private UserSignUrl = this.baseUrl + "/api/token/";
  private UserRegisterUrl = this.baseUrl + "/user/createuser/";
  private UserResterTwoUrl = this.baseUrl + "/user/api/userprofile/";
  private UserUpdateUrl = this.baseUrl + "/user/api/userprofile/";

  private ForgotPasswordUrl = this.baseUrl + "/user/reset-password/";
  private VerifyTokenUrl = this.baseUrl + "/user/verify-token/";
  private SetPasswordUrl = this.baseUrl + "/user/reset-password/confirm/";

  private getMonthlyUrl = this.baseUrl + "/user/api/monthlyplan/";
  private getWeekUrl = this.baseUrl + "/user/api/resourcegroup/";
  private postPaymentUrl = this.baseUrl + "/user/pay/";
  private postCouponCheckUrl = this.baseUrl + "/user/coupon_check/";

  
  private getMoneyUrl = this.baseUrl + "/user/getplanprice/";
  private getgenderUrl = this.baseUrl + "/user/api/gender/";
  private getmoodtyleUrl = this.baseUrl + "/user/api/modeofcontact/";
  private getCoursesUrl = this.baseUrl + "/user/plans/";

  private getMonthStatus = this.baseUrl + "/user/monthstatus/";
  private getBookingUrl = this.baseUrl + "/user/accountabilitycalls/";
  private getEmailUrl = this.baseUrl + "/user/getemail/";

  private getMembershipUrl = this.baseUrl + "/user/is_membership_bought/";
  private getUseractiveUrl = this.baseUrl + "/user/is_activated/";
  private getMembershipstartUrl = this.baseUrl + "/user/is_membership_started/";
  private getSubscriptionStartUrl = this.baseUrl + "/user/start/";
  private getsideListUrl = this.baseUrl + "/user/success_tools/";

  getToken() {
    return localStorage.getItem("token");
  }
  deleteToken() {
    return localStorage.removeItem("token");
  }

  Userpayment(val): Observable<any> {
    return this.http.post(this.postPaymentUrl, val);
  }
  CheckCoupon(val): Observable<any> {
    return this.http.post(this.postCouponCheckUrl, val);
  }

  UserLogin(val): Observable<any> {
    return this.http.post(this.UserSignUrl, val);
  }

  UserRegOne(val): Observable<any> {
    return this.http.post(this.UserRegisterUrl, val);
  }

  UserRegTwo(val): Observable<any> {
    return this.http.post(this.UserResterTwoUrl, val);
  }
  UserUpdate(val, id): Observable<any> {
    return this.http.put(this.UserUpdateUrl + id + "/", val);
  }

  ForgotPassword(val): Observable<any> {
    return this.http.post(this.ForgotPasswordUrl, val);
  }
  VerifyPassToken(val): Observable<any> {
    return this.http.post(this.VerifyTokenUrl, val);
  }
  SetPassword(val): Observable<any> {
    return this.http.post(this.SetPasswordUrl, val);
  }

  getUserDetails(): Observable<any> {
    return this.http.get(this.UserResterTwoUrl);
  }

  getBookingDetails(id): Observable<any> {
    return this.http.get(this.getBookingUrl + id + "/");
  }

  titleCase(string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence;
  }

  formErrorMessage(error, includeKeyName = false) {
    for (var key in error) {
      if (error.hasOwnProperty(key)) {
        return (
          (includeKeyName ? this.titleCase(key) + ": " : "") + error[key][0]
        );
      }
    }
  }
  getMonthPlan(month): Observable<any> {
    return this.http.get<any>(
      this.getMonthlyUrl + "?month=" + month + "&gender=1"
    );
  }

  getWeekly(weekId): Observable<any> {
    return this.http.get<any>(this.getWeekUrl + weekId + "/");
  }

  getGender(): Observable<any> {
    return this.http.get<any>(this.getgenderUrl);
  }

  getGenderById(val): Observable<any> {
    return this.http.get<any>(this.getgenderUrl + val + '/');
  }
  getContact(): Observable<any> {
    return this.http.get<any>(this.getmoodtyleUrl);
  }
  getCourses(): Observable<any> {
    return this.http.get<any>(this.getCoursesUrl);
  }
  
  getContactById(val): Observable<any> {
    return this.http.get<any>(this.getmoodtyleUrl + val + "/");
  }

  getMonthlyStatus(): Observable<any> {
    return this.http.get<any>(this.getMonthStatus);
  }
  getEmail(): Observable<any> {
    return this.http.get<any>(this.getEmailUrl);
  }

  getMembership(): Observable<any> {
    return this.http.get<any>(this.getMembershipUrl);
  }

  getUserActivate(): Observable<any> {
    return this.http.get<any>(this.getUseractiveUrl);
  }

  getMembershipStart(): Observable<any> {
    return this.http.get<any>(this.getMembershipstartUrl);
  }
  getStartSubscription(): Observable<any> {
    return this.http.get<any>(this.getSubscriptionStartUrl);
  }
  getMoney(): Observable<any> {
    return this.http.get<any>(this.getMoneyUrl);
  }

  GetStartList(): Observable<any> {
    return this.http.get<any>(this.getsideListUrl);
  }
}
