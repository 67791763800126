import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../common/share/service.service";
import { Country } from "../country";
import { TimeZone } from "../timezone";
@Component({
  selector: "app-profile-update",
  templateUrl: "./profile-update.component.html",
  styleUrls: ["./profile-update.component.css"]
})
export class ProfileUpdateComponent implements OnInit {
  formUpdate: FormGroup;
  gender;
  modecontact;
  date_of_birth: number;
  country = Country.coun;
  timeZone = TimeZone.time;

  userDetails: any = [];

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private router: Router,
    public dialogRef: MatDialogRef<ProfileUpdateComponent>
  ) { }

  ngOnInit() {
    this.initForm();
    this.getGender();
    this.getContact();
    this.UserDetails();
  }
  initForm() {
    this.formUpdate = this.fb.group({
      // user: [""],
      first_name: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]
      ],
      last_name: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]
      ],
      age: ["", [Validators.required, Validators.pattern("^[0-9 ]*$")]],
      gender: ["", Validators.required],
      phone_number: [
        "",
        [Validators.required, Validators.pattern("^[0-9 ]*$")]
      ],
      country: ["", Validators.required],
      timezone: ["", Validators.required],
      mode_of_contact: ["", Validators.required]
    });
  }

  UserDetails() {
    this.auth.getUserDetails().subscribe(result => {
      console.log('gender ', result[0].gender);
      this.userDetails = result[0];
      console.log("all data", this.userDetails);
      let g_id = result[0].gender;
      localStorage.setItem("id", result[0].id);
    });
  }

  Update(message, action) {
    console.log(this.formUpdate.value);
    let value = this.formUpdate.value;
    let d = Number(this.formUpdate.value.age);
    this.formUpdate.value.age = d;
    let id = localStorage.getItem("id");
    this.auth.UserUpdate(value, id).subscribe(result => {
      console.log("update is success");
      this.snackbar.open(message, action, {
        duration: 2000
        //  verticalPosition: "top"
      });
    });
    this.dialogRef.close();
    window.location.reload();
  }
  Back() {
    this.dialogRef.close();
  }

  getGender() {
    this.auth.getGender().subscribe(result => {
      console.log(result);
      this.gender = result;
    });
  }

  getContact() {
    this.auth.getContact().subscribe(result => {
      console.log('contact', result);
      this.modecontact = result;
    });
  }
}
