import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../common/share/service.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material';
import { SimpleDialogComponent } from "../common/dialog/simple-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ThrowStmt } from "@angular/compiler";



export interface PeriodicElement {
  id: number;
  date: string;
  time: string;
  status: string;
}

@Component({
  selector: "app-month",
  templateUrl: "./month.component.html",
  styleUrls: ["./month.component.css"]
})
export class MonthComponent implements OnInit {
  isLinear = false;
  // private routeSub: Subscription;
  displayedColumns: string[] = ["date", "time", "status"];

  dataSource = [];

  bookingLenth;
  bookings;
  showLoader = false;
  bookingUrl="";
  weekid: number;
  monthlyPdf = [];
  monthly;
  has_bookings=true;
  show_group_calls = false;
  monthname;
  weekl1video = [];
  weekl1pdf = [];
  weekl2video = [];
  weekl2pdf = [];
  weekl3video = [];
  weekl3pdf = [];
  weekl4video = [];
  weekl4pdf = [];
  CheatPdf;
  errorMessage;
  mobileapp: boolean = false;
  private routeSub;
  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // this.book();
    // this.checkingpage();
    // this.paymentCheck();
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params); //log the entire params object
      console.log("id", params["id"]); //log the value of id
      this.monthly = params["id"];
      this.getMotnlyData(params["id"]);
      this.booking(params["id"]);
      if (params["id"] == 6) {
        console.log("I am 6");
        this.mobileapp = true;
      } else {
        console.log("I am not a 6");
        this.mobileapp = false;
      }
    });
    this.checkCondition();
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
  Booking() {
    let action = "Message";
    let msgs = "Coaching package only";
    // this.snackbar.open(msgs, action, {
    //   duration: 2000
    // });
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      width: "350px",
      data: { message: msgs }
    });
  }

  getMotnlyData(month) {
    this.monthlyPdf = [];

    this.weekl1video = [];
    this.weekl1pdf = [];
    this.weekl2video = [];
    this.weekl2pdf = [];
    this.weekl3video = [];
    this.weekl3pdf = [];
    this.weekl4video = [];
    this.weekl4pdf = [];
    //start animation
    this.showLoader = true;
    this.auth.getMonthPlan(month).subscribe(
      result => {
        this.errorMessage = false;
        this.monthname = result[0].name;
        if (this.monthname.includes("Ditch The Diet Mentality") || this.monthname.includes("How To Stay Lean With A Family"))
        {
          this.show_group_calls=true;
        }
        // console.log("month gf", result);
        // console.log("week1", result[0]);
        let weekval = result[0];
        // console.log(weekval.week1, "month3");
        this.getWeeklyData1(weekval);
        this.getWeeklyData2(weekval);
        this.getWeeklyData3(weekval);
        this.getWeeklyData4(weekval);
        this.getMonthly(weekval);
        this.CheatPdf = result[0].cheat_sheets
        //close animation
        this.showLoader = false;
      },
      error => {
        // console.log("oh Set", error.error.message);
        this.errorMessage = error.error.message;
      }
    );
  }
  // 1st
  getWeeklyData1(weekval) {
    let id = weekval.week1;
    this.auth.getWeekly(id).subscribe(result => {
      // console.log("week 1", result);
      this.weekl1video = result.video_list;
      this.weekl1pdf = result.pdf_list;
      // console.log(this.weekl1video);
    });
  }

  // 2nd
  getWeeklyData2(weekval) {
    let id = weekval.week2;
    this.auth.getWeekly(id).subscribe(result => {
      // console.log("week 2", result);
      this.weekl2video = result.video_list;
      this.weekl2pdf = result.pdf_list;
      // console.log(this.weekl2video);
    });
  }

  // 3rd
  getWeeklyData3(weekval) {
    let id = weekval.week3;
    this.auth.getWeekly(id).subscribe(result => {
      // console.log("week 3", result);
      this.weekl3video = result.video_list;
      this.weekl3pdf = result.pdf_list;
    });
  }

  // 4th
  getWeeklyData4(weekval) {
    let id = weekval.week4;
    this.auth.getWeekly(id).subscribe(result => {
      console.log("week 4", result);
      this.weekl4video = result.video_list;
      this.weekl4pdf = result.pdf_list;
    });
  }

  // for month
  getMonthly(weekval) {
    let id = weekval.monthly;
    this.auth.getWeekly(id).subscribe(result => {
      var list = [];

      for (let data of result.pdf_list) {
        if (data.pdf_type.name != "Cheat Sheet") {
          // console.log("non", data);
          list.push(data);
        } else {
          // console.log("non of", data);
        }
      }
      this.monthlyPdf = list;
      // console.log("final list", list);

      var Cheat_list = [];
      for (let data of result.pdf_list) {
        // console.log("Name", data.pdf_type.name);
        if (data.pdf_type.name === "Cheat Sheet") {
          Cheat_list.push(data);
        } else {
          // console.log("I am not getting Grocery List", data);
        }
      }
      // this.CheatPdf = Cheat_list;
    });
  }

  getColor(status) {
    2;
    switch (status) {
      case "Booked":
        return "blue";
      case "Pending":
        return "red";
      case "Completed":
        return "green";
    }
  }

  checkCondition() {
   this.paymentCheck()
  }

  booking(id) {
    this.auth.getBookingDetails(id).subscribe(result => {
      // console.log("booking", result);
      this.dataSource = result.bookings;
      this.has_bookings=result.has_bookings;
      this.bookingUrl = result.link;
      // this.bookings = this.data.length - b;
      // if (this.dataSource.length < 1) {
      //   console.log("no Booking");
      // }
    });
  }

  checkingpage() {
   
  }

  paymentCheck() {
   
        this.auth.getMembership().subscribe(result => {
          // console.log("Membarship", result);
          if (result.membership_bought == true) {           
            if (result.thank_you_page == true) {
              this.router.navigate(["/thanku"]);
            }
            else
            {
              this.router.navigate(["/dashboard"]);
            }
          } else {
            this.router.navigate(["/payment"]);
          }
        });
     
    
  }
}
