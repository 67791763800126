import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../common/share/service.service";
import { Country } from "../country";
import { TimeZone } from "../timezone";
import { ErrorStateMatcher } from "@angular/material";
import { stringify } from "@angular/compiler/src/util";
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"]
})
export class SignupComponent implements OnInit {
  hide = true;
  public loading = false;
  formRegister: FormGroup;
  gender = [];
  modecontact = [];
  courses = [];
  date_of_birth: number;
  country = Country.coun;
  timeZone = TimeZone.time;
  pricetext="Price : Please select a plan first"
  selectedcounty = "CA";
  // selectedplan=1;
  selectedtimezone = "(GMT-07:00) Mountain Time (US & Canada)";
  token;
  matcher = new MyErrorStateMatcher();
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.router.navigate(["/dashboard"]);
    }
  }
  ngOnInit() {
    this.initForm();
    this.getGender();
    this.getContact();
    this.getCourses();
    // this.checkingPage();
  }
  updatePrice(planid) {
    // console.log("Plan",planid);
    // console.log(this.formRegister.value);
    let obj = this.courses.filter(data=>data.id==planid)[0];
    // return price;
    this.pricetext= "Price : "+obj.price.toString()+" "+obj.currency.toUpperCase()+ "/ " + obj.interval.toString()+ " x " +obj.num_of_intervals.toString()+" "+ obj.interval.toString()+ "s"
    // console.log(price);
}
  initForm() {
    this.formRegister = this.fb.group(
      {
        // user: [""],
        first_name: [
          "",
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]
        ],
        last_name: [
          "",
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]
        ],
        age: ["", [Validators.required, Validators.pattern("^[0-9 ]*$")]],
        gender: ["", Validators.required],
        phone_number: [
          "",
          [Validators.required, Validators.pattern("^[0-9 ]*$")]
        ],
        country: ["", Validators.required],
        timezone: ["", Validators.required],
        mode_of_contact: [""],
        plan: ["", Validators.required],
        referral: ["", [Validators.pattern("^[a-zA-Z ]*$")]],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
            )
          ]
        ],
        password: ["", Validators.required],
        conPassword: ["", Validators.required]
      },
      { validator: this.checkPasswords }
    );
  }

  SignUpTwo(message) {
    this.loading = true;
    let val = this.formRegister.value;
    // console.log(val);

    let mail = this.formRegister.value.email.toLowerCase();
    let pass = this.formRegister.value.password;

    this.auth.UserRegOne({ email: mail, password: pass }).subscribe(
      result => {
        // console.log("data", result.id);
        // let id = result.id;
        localStorage.setItem("token", result.access);
        // localStorage.setItem("id", result.id);
        let val = this.formRegister.value;
        val.mode_of_contact = 1;
        delete this.formRegister.value.email;
        delete this.formRegister.value.password;
        delete this.formRegister.value.conPassword;
        let d = Number(this.formRegister.value.age);
        this.formRegister.value.age = d;
        // let n = Number(result.id);
        // this.formRegister.value.user = n;
        // console.log(val);
        const _this = this;
        this.auth.UserRegTwo(val).subscribe(
          result => {
            // console.log("register success", result);

             this.auth.getMembership().subscribe(result => {
                  // console.log("Membarship", result);
                  if (result.membership_bought == true) {
                    if (result.thank_you_page == true) {
                      this.router.navigate(["/thanku"]);
                    }
                    else
                    {
                      this.router.navigate(["/dashboard"]);
                    }
                  } else {
                    this.router.navigate(["/payment"]);
                  }
                });
             
          },
          error => {
            // console.log("Something is wrong", error);
            //console.log();
            setTimeout(() => {
              let msg = error.error;
              this.snackbar.open(
                this.auth.formErrorMessage(msg, true),
                message,
                {
                  duration: 2000
                }
              );
              this.loading = false;
            }, 2000);
          }
        );
      },
      error => {
        // console.log("Something is wrong", error);

        setTimeout(() => {
          let msg = error.error;
          let m = "Error";
          this.snackbar.open(this.auth.formErrorMessage(msg, true), m, {
            duration: 3000
          });
          this.loading = false;
        }, 2000);
      }
    );
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.get("password").value;
    let confirmPass = group.get("conPassword").value;
    return pass === confirmPass ? null : { notSame: true };
  }

  getGender() {
    this.auth.getGender().subscribe(result => {
      // console.log(result);
      this.gender = result;
    });
  }

  getContact() {
    this.auth.getContact().subscribe(result => {
      // console.log(result);
      this.modecontact = result;
    });
  }

  getCourses() {
    this.auth.getCourses().subscribe(result => {
      // console.log(result);
      this.courses = result;
    });
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
