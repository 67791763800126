import { NgModule, Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MonthComponent } from "./month/month.component";
import { SignupComponent } from "./signup/signup.component";
import { SigninComponent } from "./signin/signin.component";
import { ForgotpassComponent } from "./forgotpass/forgotpass.component";
import { ResetpassComponent } from "./resetpass/resetpass.component";

import { ProfileComponent } from "./profile/profile.component";
import { PaymentComponent } from "./payment/payment.component";
import { StartsubscriptionComponent } from "./startsubscription/startsubscription.component";

import { ThankyouComponent } from "./thankyou/thankyou.component";

const routes: Routes = [
  { path: "", redirectTo: "signin", pathMatch: "full" },
  { path: "signin", component: SigninComponent },
  { path: "signup", component: SignupComponent },
  { path: "payment", component: PaymentComponent },
  { path: "startsubscription", component: StartsubscriptionComponent },

  { path: "thanku", component: ThankyouComponent },
  { path: "forgotpassword", component: ForgotpassComponent },
  { path: "resetpassword", component: ResetpassComponent },

  {
    path: "dashboard",
    component: DashboardComponent,
    children: [
      { path: "", redirectTo: "month/1", pathMatch: "full" },
      { path: "month/:id", component: MonthComponent },
      { path: "profile", component: ProfileComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
