import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../common/share/service.service";
import {
  StripeService,
  Elements,
  Element as StripeElement,
  ElementsOptions
} from "ngx-stripe";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SimpleDialogComponent } from "../common/dialog/simple-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { PaymentsuccessComponent } from "../common/paymentsuccess/paymentsuccess.component";
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"]
})
export class PaymentComponent implements OnInit {
  elements: Elements;
  card: StripeElement;
  error = [];
  error2 = [];
  success = [];
  plan;
  UserDetails;
  coupon_message="";
  pay_message="";
  apply_coupon=false;
  coupon="";
  coupon_message_color="red";
  email;
  token;
  public loading = false;

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: "en"
  };

  stripeTest: FormGroup;
  couponForm: FormGroup;


  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private auth: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem("token");
    if (this.token == null) {
      this.router.navigate(["/signin"]);
    }
  }
  //data;
  ngOnInit() {
    this.getMoneyPrice();
    this.getUserDetail();
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]]
    });
    this.couponForm = this.fb.group({
      coupon_code: ["", [Validators.required]]

    });
    this.stripeService.elements(this.elementsOptions).subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#BBBBBB",
              color: "#31325F",
              lineHeight: "40px",
              fontWeight: 300,

              fontFamily: "Times New Roman",

              fontSize: "18px",
              "::placeholder": {
                color: "#BBBBBB"
              }
            }
          }
        });
        this.card.mount("#card-element");
      }
    });
  }

  getMoneyPrice() {
    this.auth.getMoney().subscribe(result => {
      this.plan = result;
      this.pay_message =      this.titleCase(this.plan.interval)+"ly Subscription (for "+ String(this.plan.num_of_intervals) +" "+ String(this.plan.interval)+"s): <br /> $ "+String(this.plan.price)+" "+this.plan.currency.toUpperCase()+"/"+this.plan.interval;
    });
  }
  getUserDetail() {
    this.auth.getUserDetails().subscribe(result => {
      this.UserDetails = result[0];
      console.log("user data", result[0]);
      this.auth.getEmail().subscribe(result => {
        console.log(result);
        this.email = result;
      });
    });
  }
   titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }
  checkcoupon()
  {
    let code = this.couponForm.get("coupon_code").value;
    this.coupon=code;
    this.loading = true;
    this.auth.CheckCoupon({ coupon_code: code }).subscribe(
      result => {
        console.log("@ 77 is ", result);
        // let val = result[0];
        // this.handleServerResponse(val);
        this.loading = false;
        this.apply_coupon=true;
        this.coupon_message_color="green";
        this.coupon_message=result.message;
        let coupon_obj = result.coupon;
        let new_price = null;
        if(coupon_obj.percent_off==null)
        {
          new_price = this.plan.price-(coupon_obj.amount_off/100);
        }
        else{
          new_price = this.plan.price * (100-coupon_obj.percent_off)/100;
        }
        new_price=Math.round(new_price*100)/100;
        if(coupon_obj.duration=='once')
        {
          this.pay_message =     this.titleCase( this.plan.interval)+"ly Subscription (for "+ String(this.plan.num_of_intervals) +" "+ String(this.plan.interval)+"s): <br /> $ "+String(new_price)+" "+this.plan.currency.toUpperCase()+" for the 1st "+this.plan.interval+" and "+String(this.plan.price)+"/"+this.plan.interval+ " for the rest!";

        }
        else{
          
          this.pay_message =      this.titleCase(this.plan.interval)+"ly Subscription (for "+ String(this.plan.num_of_intervals) + " "+String(this.plan.interval)+"s): <br /> $ "+String(new_price)+" "+this.plan.currency.toUpperCase()+"/"+this.plan.interval;

        }

      },
      error => {
        this.loading = false;
        console.log("failed", error);
        console.log(error.error);
        let errorMsg = error.error.message;
        this.coupon_message=errorMsg;
        this.apply_coupon=false;
        this.coupon_message_color="red";

        this.pay_message =      this.titleCase(this.plan.interval)+"ly Subscription (for "+ String(this.plan.num_of_intervals) +" "+ String(this.plan.interval)+"s): <br /> $ "+String(this.plan.price)+" "+this.plan.currency.toUpperCase()+"/"+this.plan.interval;

        // const dialogRef = this.dialog.open(SimpleDialogComponent, {
        //   width: "350px",
        //   data: { message: errorMsg }
        // });
      }
    );
  }
  buy() {
    const name = this.stripeTest.get("name").value;
    this.loading = true;

    this.stripeService
      .createPaymentMethod("card", this.card)
      .subscribe(result => {
        // Use the token to create a charge or a customer
        // https://stripe.com/docs/charges
        console.log(result.paymentMethod);
        if (result.paymentMethod) {
          let id = result.paymentMethod.id;
          console.log("id", id);
          let to_pass = { payment_method_id: id };
          if(this.apply_coupon)
          {
            to_pass['coupon_code']=this.coupon;
          }
          else{
            to_pass['coupon_code']=null;
          }

          this.auth.Userpayment(to_pass).subscribe(
            result => {
              console.log("@ 77 is ", result);
              // console.log("success", JSON.parse(result[0]));
              let val = result[0];
              this.handleServerResponse(val);
              this.loading = false;
            },
            error => {
              this.loading = false;
              console.log("failed", error);
              console.log(error.error);
              let errorMsg = error.error.error;
              const dialogRef = this.dialog.open(SimpleDialogComponent, {
                width: "350px",
                data: { message: errorMsg }
              });
            }
          );
        } else if (result.error) {
          // Error creating the token
          this.loading = false;
          console.log(result.error);
          let errorMsg = result.error.message;
          const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: "350px",
            data: { message: errorMsg }
          });
        }
      });
  }
  cancel() {
    this.auth.deleteToken();

    window.location.href = "https://ndelish.com/";
  }

  handleServerResponse(response) {
    if (response.error) {
      let error = response.error;
      // Show error from server on payment form
      console.log(response.error);
      this.loading = false;
      console.log("failed", error);
      // console.log(error.error);
      // let errorMsg = error.error.error;
      const dialogRef = this.dialog.open(SimpleDialogComponent, {
        width: "350px",
        data: { message: "Could not successfully process payment.Please retry ." }
      });
    } else if (response.requires_action) {
      console.log("I requires", response);
      // Use Stripe.js to handle required card action
      var _this = this;
      let data = this.stripeService
        .handleCardAction(response.payment_intent_client_secret)
        .subscribe(function handleStripeJsResult(result) {
          console.log("I am RIshabh jain");
          console.log("i am checking", result);
          if (result.error) {
            // Show error in payment form
            console.log("failed1", result.error);
            // console.log(response.error);
            this.loading = false;
            // console.log("failed", error);
            // console.log(error.error);
            let errorMsg = result.error.message;
            const dialogRef = _this.dialog.open(SimpleDialogComponent, {
              width: "350px",
              data: { message: errorMsg }
            });
          } else {
            console.log(this);
            // const _that = _this;
            _this.auth
              .Userpayment({ payment_intent_id: result.paymentIntent.id })
              .subscribe(
                result => {
                  console.log("success4000", result);
                  // const _this = _that;
                  const dialogRef = _this.dialog.open(PaymentsuccessComponent, {
                    width: "350px",
                    data: { message: "Your payment is successful." }
                  });
                  // const _this = this;
                  dialogRef.afterClosed().subscribe(function () {
                  
                        _this.router.navigate(["/dashboard"]);
                    
                  });
                },
                error => {
                  console.log("failed2", error);
                  // console.log(response.error);
                  this.loading = false;
                  // console.log("failed", error);
                  // console.log(error.error);
                  let errorMsg = error.error.error;
                  const dialogRef = _this.dialog.open(SimpleDialogComponent, {
                    width: "350px",
                    data: { message: errorMsg }
                  });
                }
              );
          }
        });
      console.log("response=", data);
    } else {
      // Show success message
      this.loading = false;

      console.log("success4242", response);
      const dialogRef = this.dialog.open(PaymentsuccessComponent, {
        width: "350px",
        data: { message: "Your payment is successful." }
      });
      const _this = this;
      dialogRef.afterClosed().subscribe(function () {
       
            _this.router.navigate(["/dashboard"]);
         
      });
    }
  }
}
