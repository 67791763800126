import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { AuthService } from "../common/share/service.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxLoadingService } from "ngx-loading";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"]
})
export class SigninComponent implements OnInit {
  hide = true;
  formLogin: FormGroup;
  public loading = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    private ngxloader: NgxLoadingService
  ) { }

  ngOnInit() {
    if (this.auth.getToken() != null) {
      this.router.navigateByUrl("/dashboard");
      //      dashboard
    }
    this.initForm();
  }

  initForm() {
    this.formLogin = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
          )
        ]
      ],
      password: ["", Validators.required]
    });
  }

  // loader() {
  //    this.ngxloader.start();
  //   setTimeout(() => {
  //     this.ngxloader.loadingConfig();
  //   }, 20000);
  // }

  SignIn(action) {
    this.loading = true;

    let valEmail = this.formLogin.value.email.toLowerCase();
    let valPass = this.formLogin.value.password;

    // console.log(val);
    this.auth.UserLogin({ 'email': valEmail, 'password': valPass }).subscribe(
      result => {
        // console.log(result);
        setTimeout(() => {
          this.formLogin.reset();
          let msg = "User Login";
          this.snackbar.open(msg, action, {
            duration: 2000
            //  verticalPosition: "top"
          });
          this.loading = false;
        }, 2000);
        // console.log("Login Successful");
        localStorage.setItem("token", result.access);

         this.auth.getMembership().subscribe(result => {
             
              if (result.membership_bought == true) {
                if (result.thank_you_page == true) {
                  this.router.navigate(["/thanku"]);
                }
                else
                {
                  this.router.navigate(["/dashboard"]);
                }
                
                 
              } else {
                this.router.navigate(["/payment"]);
              }
            });
      
      },
      error => {
        setTimeout(() => {
          let msg = error.error;
          let action = " Error";
          let msgs = "Your email or password is wrong";
          this.snackbar.open(msgs, action, {
            duration: 2000
            // verticalPosition: "top"
          });
          this.loading = false;
        }, 2000);
      }
    );
  }


}
