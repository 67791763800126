import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from "@angular/material/dialog";

import { AuthService } from "../common/share/service.service";
import { ProfileUpdateComponent } from "../profile-update/profile-update.component";

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { Country } from "../country";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
  userDetails;
  genderProfile;
  contact;
  email;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.UserDetails();
  }

  UserDetails() {
    this.auth.getUserDetails().subscribe(result => {
      console.log(result[0].gender);
      this.userDetails = result[0];
      console.log("all data", this.userDetails);
      let g_id = result[0].gender;
      localStorage.setItem("id", result[0].id);
      let c_id = result[0].mode_of_contact;
      this.auth.getGenderById(g_id).subscribe(result => {
        console.log(result.gender_type);
        this.genderProfile = result;
      });
      this.auth.getContactById(c_id).subscribe(result => {
        console.log(result);
        this.contact = result;
      });
      this.auth.getEmail().subscribe(result => {
        console.log(result);
        this.email = result;
      });
    });
  }

  update() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    this.dialog.open(ProfileUpdateComponent, dialogConfig);
  }
}
