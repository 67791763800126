import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../common/share/service.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-thankyou",
  templateUrl: "./thankyou.component.html",
  styleUrls: ["./thankyou.component.css"]
})
export class ThankyouComponent implements OnInit {
  token;
  constructor(
    private router: Router,
    private auth: AuthService,
    private snackbar: MatSnackBar
  ) {
    this.token = localStorage.getItem("token");
    if (this.token == null) {
      this.router.navigate(["/signin"]);
    }
  }

  ngOnInit() {
    this.checkCondition();
  }

  logout() {
    this.auth.deleteToken();

    window.location.href = "https://ndelish.com/";
  }
  checkCondition() {
  //   this.auth.getUserDetails().subscribe(res => {
  //     console.log("register success", res, res.is_activated);
  //     if (res[0]["is_activated"] == true) {
  //       console.log("I am dashboard");
  //       this.router.navigate(["/dashboard"]);
  //     } else {
  //       console.log("I am Thanku page");
  //       this.router.navigate(["/dashboard"]);
  //     }
  //   });
   }

}
