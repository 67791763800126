import { Component } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { AuthService } from "../share/service.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.css"]
})
export class SidenavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public loading = false;
  public selected = 1;
  public months;
  fetchSidepdf;
  showFiller = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private router: Router,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getMonthStatus();
    this.GetSideList();
  }

  // profile() {
  //   this.router.navigate(["/profile"]);
  // }
  //update start list

  logout(message) {
    localStorage.removeItem("token");

    let msg = "Logout ";
    this.snackbar.open(msg, message, {
      duration: 2000
    });
    this.router.navigate(["/signin"]);
  }

  Policy() {
    window.open('https://ndelish.com/nutritious-delicious-policy/')
  }

  getMonthStatus() {
    this.auth.getMonthlyStatus().subscribe(
      result => {
        this.months = result;
      },
      error => {
        console.log(error.error);
      }
    );
  }

  getMonth(month) {
    // let month1 = "1";
    console.log("I am :", month);
    localStorage.setItem("month", month);
  }
  GetSideList() {
    this.auth.GetStartList().subscribe(result => {
      // console.log("Data", result);
      this.fetchSidepdf = result;
    });
  }
}
