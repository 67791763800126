import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialsModule } from "./common/materials/materials.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

// import { CoreModule } from './core/core.module';
import { NgxLoadingModule } from "ngx-loading";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { NgxStripeModule } from "ngx-stripe";

import { AuthService } from "./common/share/service.service";
import { TokenInterceptor } from "./common/share/interceptor.service";

import { AppComponent } from "./app.component";
import { SidenavComponent } from "./common/sidenav/sidenav.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MonthComponent } from "./month/month.component";
import { SignupComponent } from "./signup/signup.component";
import { SigninComponent } from "./signin/signin.component";
// import { SignupTwoComponent } from "./signup-two/signup-two.component";
import { ThankyouComponent } from "./thankyou/thankyou.component";
import { ProfileComponent } from "./profile/profile.component";
import { ProfileUpdateComponent } from "./profile-update/profile-update.component";
import { PaymentComponent } from "./payment/payment.component";
import { ForgotpassComponent } from "./forgotpass/forgotpass.component";
import { SimpleDialogComponent } from "./common/dialog/simple-dialog.component";

import { ResetpassComponent } from "./resetpass/resetpass.component";
import { StartsubscriptionComponent } from "./startsubscription/startsubscription.component";
import { PaymentsuccessComponent } from "./common/paymentsuccess/paymentsuccess.component";

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    DashboardComponent,
    MonthComponent,
    SignupComponent,
    SigninComponent,
    // SignupTwoComponent,
    ThankyouComponent,
    ProfileComponent,
    ProfileUpdateComponent,
    PaymentComponent,
    ForgotpassComponent,
    ResetpassComponent,
    StartsubscriptionComponent,
    SimpleDialogComponent,
    PaymentsuccessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    NgxStripeModule.forRoot("pk_live_n3Uo7zwevHKmENZeUeui073B"),
    // NgxStripeModule.forRoot("pk_test_lTAggkjuDrOaf9v26sxh5JZw"),

    NgxUiLoaderModule
  ],
  entryComponents: [
    ProfileUpdateComponent,
    ResetpassComponent,

    ForgotpassComponent,
    SimpleDialogComponent,
    PaymentsuccessComponent
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
